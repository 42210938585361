import React from "react";
import classnames from "classnames";
import pokeball from "./images/temple.png";
import "./card.scss";

const Card = ({ onClick, card, index, isInactive, isFlipped, isDisabled }) => {
  const handleClick = () => {
    !isFlipped && !isDisabled && onClick(index);
  };

  return (
    <div
      className={classnames("card", {
        "is-flipped": isFlipped,
        "is-inactive": isInactive
      })}
      onClick={handleClick}
    >
      <div className="card-face card-font-face">
    
        <img src={pokeball} alt="temple" />
      </div>
      <div className="card-face card-back-face whitebg">
      
    {card?.text.includes('(') ? <b>{card?.text}</b> : card?.text}
      </div>
    </div>
  );
};

export default Card;
